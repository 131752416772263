import React from "react";

const experienceContent = [
    {
        year: "   2019 - Present",
        position: " Software Engineer (Tech Lead)",
        compnayName: "Willis Towers Watson",
        details: `  •	Develop full stack software solutions and see solutions through the entire software development lifecycle building internal tooling for large global corporation.
        <br><br>    •	Consult UI, UX, and Security teams when determine help from outside the team could be beneficial.
        <br><br>    •	Work in a team environment and participate in daily stand up meetings as well backlog grooming and retrospective meetings. 
        <br><br>    •	Contribute new features and bug fixes in React client-side SPA application’s and Asp.net MVC and Core backends as well many Asp.Net Core web api projects.
        <br><br>    •	Mob and pair program with junior developer’s on stories and bug fixes in effort to spread knowledge and increase productivity to stakeholders.
        `,
    },
    {
        year: "2014 - 2019",
        position: " Software Engineer & Architecture Lead",
        compnayName: "GMetrix",
        details: `•	Create NodeJs and React based panels that offer our test runners in all of the Adobe Applications via html panels. Developed the build pipeline and implemented a full continuous integration of the panels to allow for the shortest time to push changes. Created the installers to distribute the software. Most recently developed a cross-platform Electron, NodeJs and React based port of desktop client application to administer several of Gmetrix test runners ranging from Unity simulations to full blown COM based Microsoft Office interop of which offers a consistent feel throughout all the Gmetrix software’s.
        <br><br>    •	Design and develop a WPF desktop software (Skills Management System) that interop's with Microsoft Office and Autodesk applications to administer practice certification tests and offers flash based simulation tests for many other software products such as Adobe and Autodesk. 
        <br><br>    •	Designed and developed a desktop question and validation code snippet editor that practice test question authors use to write and test questions and their validation code snippets. The code editor offers C# and VB syntax highlighting, intellisense, a rich find and replace dialog, as well a code snippet manager. The tool also offers a code modification history feature where a code snippets history can be visualized and tracked.
        <br><br>    •	Design, develop, and maintain all the web services for which the client application consumes. The web service use Asp.net Web Api. The architecture includes a custom performance counter library and code attributes that can be placed on controller actions to register to one of nine custom performance counters. The performance counters are created and registered with the operating system via a PowerShell script. Also implemented a custom caching service to help with performance and resource use.
        <br><br>    •	Design and develop many web tools including a Asp.net based localization tool where developers upload resx resource files for language translators to translate into their language. Also built many Asp.net MVC content management pages. Most recently created a Node Webkit based Adobe software panel extention that delivers the companies practice test content inside the application.
        <br><br>    •	Work mostly unsupervised and often create my own work thinking of features aimed for making better user experiences and ranging from the user interface through to the web services. I research modern technologies and develop software architectures that implement common design patterns such as IOC and SOLID principles for the projects to be more easily maintained and scaled by other developers in the future.
        `,
    },
    {
        year: "2004 - Present",
        position: "Website Developer & Content Creator",
        compnayName: "Internet Pro Designs",
        details: `  
        •	Primary responsibilities included design and development of server-side code, as well as working with a designer to build several websites to work with the companies Content Management System.
        <br><br>    •	Make content for front end of many new websites in photoshop and Fireworks.
        <br><br>    •	Author code to incorporate many open source tools in the company’s content management system.  `,
    },
];

const Experience = () => {
    return (
        <ul>
            {experienceContent.map((val, i) => (
                <li key={i}>
                    <div className="icon">
                        <i className="fa fa-briefcase"></i>
                    </div>
                    <span className="time open-sans-font text-uppercase">{val.year}</span>
                    <h5 className="poppins-font text-uppercase">
                        {val.position}
                        <span className="place open-sans-font">{val.compnayName}</span>
                    </h5>
                    <p className="open-sans-font" dangerouslySetInnerHTML={{__html: val.details}}></p>
                </li>
            ))}
        </ul>
    );
};

export default Experience;
