import React from "react";

const educationContent = [
    {
        year: "2013 - 2018",
        degree: "COMPUTER SCIENCE",
        institute: "Utah Valley University",
        details: `  •	Microsoft student partner.`,
    },
    {
        year: "2012 - 2013",
        degree: "COMPUTER SCIENCE",
        institute: "Baker College",
        details: `  •	Worked toward a bachelor in Computer Science`,
    }
];

const Education = () => {
    return (
        <ul>
            {educationContent.map((val, i) => (
                <li key={i}>
                    <div className="icon">
                        <i className="fa fa-briefcase"></i>
                    </div>
                    <span className="time open-sans-font text-uppercase">{val.year}</span>
                    <h5 className="poppins-font text-uppercase">
                        {val.degree}
                        <span className="place open-sans-font">{val.institute}</span>
                    </h5>
                    <p className="open-sans-font">{val.details}</p>
                </li>
            ))}
        </ul>
    );
};

export default Education;
