import React from "react";

const skillsContent = [
    {skillClass: "p100", skillPercent: "100", skillName: "C#"},
    {skillClass: "p89", skillPercent: "89", skillName: "JAVASCRIPT"},
    {skillClass: "p90", skillPercent: "90", skillName: "VB.NET"},
    {skillClass: "p75", skillPercent: "75", skillName: "PYTHON"},
    {skillClass: "p66", skillPercent: "66", skillName: "JAVA"},
    {skillClass: "p50", skillPercent: "50", skillName: "C++"},
    {skillClass: "p70", skillPercent: "70", skillName: "POWERSHELL"},
    {skillClass: "p50", skillPercent: "50", skillName: "PHP"},
    {skillClass: "p65", skillPercent: "65", skillName: "SQL"},
    {skillClass: "p50", skillPercent: "50", skillName: "MYSQL"},
    {skillClass: "p75", skillPercent: "75", skillName: "SQLITE"},
    {skillClass: "p50", skillPercent: "50", skillName: "SQL SERVER"},
    {skillClass: "p100", skillPercent: "100", skillName: "BRIGHTSTAR"},
    {skillClass: "p80", skillPercent: "80", skillName: "ADO.NET"},
    {skillClass: "p75", skillPercent: "75", skillName: "ORM's"},
    {skillClass: "p75", skillPercent: "85", skillName: "ASP.NET"},
    {skillClass: "p75", skillPercent: "80", skillName: "NODEJS"},
];

const Skills = () => {
    return (
        <>
            {skillsContent.map((val, i) => (
                <div className="col-6 col-md-3 mb-3 mb-sm-5" key={i}>
                    <div className={`c100 ${val.skillClass}`}>
                        <span>{val.skillPercent}%</span>
                        <div className="slice">
                            <div className="bar"></div>
                            <div className="fill"></div>
                        </div>
                    </div>
                    <h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
                        {val.skillName}
                    </h6>
                </div>
            ))}
        </>
    );
};

export default Skills;
