

const heroContent = {
    heroImage: "img/hero/me.png",
    heroMobileImage: "img/hero/me.png",
    heroTitleName: "Tommy Simper",
    heroDesignation: "Software Engineer",
    heroDescriptions: `I'm a Utah based software developer & front‑end developer focused on
  crafting clean & user‑friendly experiences, I am passionate about
  building excellent software that improves the lives of those
  around me.`,
    heroBtn: "more about me",
};

export default heroContent;