import React from "react";

const achievementsContent = [
    {title: "20", subTitle1: "years of", subTitle2: "experience"},
    {title: "8", subTitle1: "years", subTitle2: "full time"},
    {title: "Many", subTitle1: "Open Source", subTitle2: "contributions"},
    // {title: "53", subTitle1: "awards", subTitle2: "won"},
];

const Achievements = () => {
    return (
        <div className="row">
            {achievementsContent.map((val, i) => (
                <div className="col-6" key={i}>
                    <div className="box-stats with-margin">
                        <h3 className="poppins-font position-relative">{val.title}</h3>
                        <p className="open-sans-font m-0 position-relative text-uppercase">
                            {val.subTitle1} <span className="d-block">{val.subTitle2}</span>
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Achievements;
