import React from "react";

const personalInfoContent = [
    {meta: "first name", metaInfo: "Tommy"},
    {meta: "last name", metaInfo: "Simper"},
    {meta: "Age", metaInfo: "39 Years"},
    {meta: "Nationality", metaInfo: "American"},
    {meta: "Freelance", metaInfo: "Available"},
    {meta: "Address", metaInfo: "Eagle Mountain Utah"},
    {meta: "phone", metaInfo: "+21621184010"},
    {meta: "Email", metaInfo: "tommysimper@gmail.com"},
    {meta: "Skype", metaInfo: " tommy.simper"},
    {meta: "langages", metaInfo: "English"},
];

const PersonalInfo = () => {
    return (
        <ul className="about-list list-unstyled open-sans-font">
            {personalInfoContent.map((val, i) => (
                <li key={i}>
                    <span className="title">{val.meta}: </span>
                    <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
                        {val.metaInfo}
                    </span>
                </li>
            ))}
        </ul>
    );
};

export default PersonalInfo;
